import {
  ButtonGroup,
  Flex,
  Icon,
  IconButton,
  SimpleGrid,
  VisuallyHidden,
  useColorModeValue,
  Container,
} from "@chakra-ui/react";
import React from "react";


import { site } from "../../constants/site";
import Link from "next/link";

const LinkWrapper = (props) => {
  const { children, href, ...rest } = props;

  return (
    <Link
      href={href}
      passHref
      target="_blank"
      rel="noopener noreferrer nofollow"
      {...rest}
    >
      {children}
    </Link>
  );
};

function SosyalMediaIcon() {
  // Filter and sort the social media links
  const activeLinks = site.sosyalMediaLinks
    .filter((item) => item.isActive)
    .sort((a, b) => a.id - b.id);

  return (
    <Container>
      <SimpleGrid
        columns={{ base: 5 }}
        spacing={2}
        itemScope="itemscope"
        itemType="https://schema.org/Organization"
      >
        {activeLinks.map((link) => (
          <LinkWrapper href={link.link} itemProp="sameAs" key={link.id}>
            <IconButton
              cursor={"pointer"}
              aria-label={link.label}
              icon={<Icon as={link.icon} fontSize="1.25rem" />}
              bg={"transparent"}
              color={useColorModeValue("white", "white")}
              colorScheme="blue"
            />
            <VisuallyHidden>{link.label}</VisuallyHidden>
          </LinkWrapper>
        ))}
      </SimpleGrid>
    </Container>
  );
}

export default SosyalMediaIcon;
