import React from "react";
import { site } from "../constants/site";
import PinterestAnalytics from "../plugins/PinterestAnalytics";
import GoogleAnalytics from "../plugins/GoogleAnalytics";
import FacebookPixel from "../plugins/FacebookPixel";
import GoogleTagManager from "../plugins/GoogleTagManager";
import TiktokPixel from "../plugins/TiktokPixel";

function Analytics() {
  const analyticsComponents = [
    {
      Component: PinterestAnalytics,
      code: site.analyticsCodes.pinterestAnalyticsCode,
      name: "Pinterest Analytics",
      key: "pinterest",
      isActive: false, 
    },
    {
      Component: GoogleAnalytics,
      code: site.analyticsCodes.googleAnalyticsCode,
      name: "Google Analytics",
      key: "google",
      isActive: false, 
    },
    {
      Component: FacebookPixel,
      code: site.analyticsCodes.facebookPixelCode,
      name: "Facebook Pixel",
      key: "facebook",
      isActive: false, 
    },
    {
      Component: GoogleTagManager,
      code: site.analyticsCodes.gtmCode,
      name: "Google Tag Manager",
      key: "gtm",
      isActive: false,
    },
    {
      Component: TiktokPixel,
      code: site.analyticsCodes.ttCode,
      name: "TikTok Pixel",
      key: "tiktok",
      isActive: false, 
    },
  ];

  return (
    <>
      {analyticsComponents
        .filter(({ isActive }) => isActive)
        .map(({ Component, code, name }) => (
          <Component key={name} code={code} />
        ))}
    </>
  );
}

export default Analytics;
