import Link from "next/link";
import { useRouter } from "next/router";
import React from "react";
import {
  useBreakpointValue,
  useColorMode,
  useColorModeValue,
} from "@chakra-ui/react";

function Logo({ locale = "tr", position = "header", isLink = true }) {
  const cursor = isLink ? "pointer" : "default";

  const platform = useBreakpointValue({ base: "mobile", md: "desktop" });

  const size = {
    header: {
      mobile: { h: 35, w: 125 },
      desktop: { h: 50, w: 150 },
    },
    footer: {
      mobile: { h: 75, w: 150 },
      desktop: { h: 100, w: 175 },
    },
    login: {
      mobile: { h: 100, w: 100 },
      desktop: { h: 150, w: 150 },
    },
  }[position][platform];

  const color = {
    header: useColorModeValue("black", "white"),
    footer: useColorModeValue("#fff", "#fff"),
    login: useColorModeValue("#fff", "#fff"),
  }[position];

  return (
    <>
      {isLink ? (
        <Link href="/" rel="home" locale={locale}>
          <LogoImage size={size} cursor={cursor} color={color} />
        </Link>
      ) : (
        <LogoImage size={size} cursor={cursor} color={color} />
      )}
    </>
  );
}

const LogoImage = ({ size, cursor, color }) => {
  return (
    <svg
      width={size.w}
      height={size.h}
      viewBox="0 0 1728.000000 334.000000"
      preserveAspectRatio="xMidYMid meet"
      style={{ cursor }}
      fill={color}
    >
      <g
        transform="translate(0.000000,334.000000) scale(0.100000,-0.100000)"
        stroke="none"
      >
        <path d="M2765 2723 c-142 -129 -195 -185 -195 -206 0 -29 218 -237 248 -237 25 0 52 25 52 48 0 9 -39 56 -87 105 l-88 88 93 87 c50 48 92 92 92 97 0 22 -32 55 -53 55 -12 0 -40 -17 -62 -37z" />
        <path d="M3276 2744 c-29 -29 -17 -52 77 -139 l92 -86 -87 -85 c-88 -84 -104 -114 -76 -142 29 -29 60 -13 167 91 58 56 110 113 116 126 9 22 0 33 -110 137 -122 116 -148 130 -179 98z" />
        <path d="M720 1780 l0 -890 170 0 170 0 0 360 0 360 435 0 435 0 0 -360 0 -360 165 0 165 0 0 890 0 890 -165 0 -165 0 0 -375 0 -375 -435 0 -435 0 -2 373 -3 372 -167 3 -168 2 0 -890z" />
        <path d="M9440 1780 l0 -890 70 0 70 0 0 400 0 400 510 0 510 0 0 65 0 65 -510 0 -510 0 0 355 0 355 530 0 530 0 0 70 0 70 -600 0 -600 0 0 -890z" />
        <path d="M12150 1780 l0 -890 60 0 60 0 0 363 c0 218 4 387 11 423 35 192 187 340 372 362 186 23 341 -40 410 -166 54 -99 57 -131 57 -574 l0 -408 60 0 60 0 0 418 c0 459 -3 490 -61 604 -59 116 -163 198 -300 233 -92 24 -271 17 -354 -15 -86 -33 -162 -86 -209 -147 l-41 -53 -3 370 -2 370 -60 0 -60 0 0 -890z" />
        <path d="M16000 1780 l0 -890 65 0 65 0 0 890 0 890 -65 0 -65 0 0 -890z" />
        <path d="M7745 2468 c-52 -146 -275 -814 -273 -820 2 -4 91 57 198 135 l195 143 92 270 c50 148 92 272 92 277 1 4 -67 7 -149 7 -112 0 -152 -3 -155 -12z" />
        <path d="M13440 2083 c0 -168 2 -304 4 -302 3 2 32 77 65 167 l61 163 0 139 0 140 -65 0 -65 0 0 -307z" />
        <path d="M16351 2083 c0 -173 4 -299 9 -288 4 11 33 87 64 169 l56 149 0 138 0 139 -65 0 -65 0 1 -307z" />
        <path d="M4180 2166 c-264 -56 -408 -226 -370 -438 6 -35 22 -81 35 -104 29 -50 100 -116 153 -143 69 -35 203 -69 322 -80 191 -19 250 -50 250 -132 0 -52 -52 -111 -117 -133 -126 -43 -358 12 -498 119 l-45 35 -75 -108 -75 -107 57 -53 c65 -60 184 -123 283 -149 95 -26 361 -25 445 0 129 40 218 100 271 184 93 144 79 344 -32 456 -82 83 -202 125 -419 147 -60 6 -131 18 -157 26 -114 37 -133 155 -34 207 32 16 64 22 143 25 123 5 216 -18 286 -72 l45 -34 86 101 86 102 -33 27 c-45 38 -161 95 -232 114 -83 22 -293 27 -375 10z" />
        <path d="M5411 2169 c-287 -56 -471 -254 -511 -549 -39 -291 92 -566 324 -680 126 -62 188 -75 356 -75 120 1 159 4 225 23 106 30 210 82 269 135 l47 42 -95 95 -96 95 -28 -18 c-232 -152 -562 -114 -656 75 -14 28 -26 62 -26 75 l0 23 484 0 484 0 7 50 c17 120 -20 315 -81 431 -72 136 -215 240 -379 274 -76 16 -253 18 -324 4z m289 -278 c94 -28 173 -111 187 -195 l6 -36 -337 0 -337 0 7 23 c32 104 120 183 239 214 54 14 178 11 235 -6z" />
        <path d="M11290 2164 c-99 -17 -145 -31 -220 -66 -150 -72 -275 -221 -324 -388 -46 -152 -27 -349 46 -500 49 -102 149 -207 243 -257 249 -132 601 -88 801 100 l66 61 -24 18 c-68 55 -63 54 -116 6 -161 -144 -457 -182 -654 -82 -70 36 -159 123 -196 192 -32 61 -62 151 -62 189 l0 23 565 0 565 0 0 98 c0 300 -161 517 -434 587 -60 15 -212 27 -256 19z m224 -129 c210 -55 324 -193 349 -422 l5 -43 -509 0 -509 0 0 21 c0 12 7 49 16 83 45 176 180 314 351 361 67 18 226 18 297 0z" />
        <path d="M14180 2151 c-105 -34 -177 -82 -225 -150 -15 -22 -31 -38 -36 -35 -5 3 -9 45 -9 95 l0 89 -55 0 -55 0 0 -630 0 -630 59 0 60 0 3 428 c3 406 4 429 24 478 72 175 210 261 403 252 74 -3 99 -9 153 -36 120 -59 197 -168 218 -307 5 -39 10 -238 10 -442 l0 -373 60 0 59 0 3 428 c3 417 4 428 26 482 49 120 152 213 265 241 217 53 423 -52 489 -251 21 -62 22 -86 26 -482 l4 -418 59 0 59 0 0 408 c0 458 -4 495 -66 612 -176 335 -698 351 -888 27 l-38 -66 -29 58 c-55 111 -160 191 -295 226 -79 20 -215 19 -284 -4z" />
        <path d="M8550 2137 c-80 -27 -140 -62 -202 -116 l-46 -41 -6 27 c-2 16 -8 54 -11 86 l-7 58 -85 -3 -86 -3 -53 -150 -53 -150 0 -477 -1 -478 155 0 155 0 0 348 c0 383 4 413 62 504 110 173 385 186 495 24 60 -88 63 -111 63 -513 l0 -363 155 0 155 0 0 375 c0 430 -6 479 -71 613 -67 138 -177 231 -314 267 -89 23 -226 19 -305 -8z" />
        <path d="M2433 1753 c5 -434 10 -474 74 -603 153 -313 557 -373 832 -125 l39 35 6 -67 c12 -112 -1 -103 152 -103 l134 0 0 625 0 625 -150 0 -150 0 0 -323 c0 -191 -4 -348 -11 -382 -38 -205 -218 -321 -412 -266 -60 17 -130 79 -162 142 -37 73 -45 154 -45 507 l0 322 -156 0 -156 0 5 -387z" />
        <path d="M6171 2068 c17 -40 129 -316 250 -614 l220 -541 -112 -259 c-61 -142 -113 -265 -116 -271 -4 -10 33 -13 164 -13 167 0 170 0 180 23 9 19 700 1621 740 1715 l13 32 -172 -2 -172 -3 -111 -266 c-61 -146 -140 -343 -175 -437 -36 -94 -67 -168 -71 -164 -3 4 -54 147 -113 317 -59 171 -126 364 -149 430 l-43 120 -182 3 -181 2 30 -72z" />
        <path d="M13503 1812 l-63 -179 0 -371 0 -372 65 0 65 0 0 550 c0 303 -1 550 -2 550 -2 0 -31 -80 -65 -178z" />
        <path d="M16412 1815 l-62 -180 0 -372 0 -373 60 0 c39 0 62 4 65 13 3 6 4 255 3 552 l-3 540 -63 -180z" />
        <path d="M7630 1684 c-102 -75 -192 -143 -201 -152 -14 -14 -219 -610 -219 -635 0 -4 66 -7 148 -7 l147 0 159 460 c87 253 157 462 155 465 -3 2 -88 -57 -189 -131z" />
      </g>
    </svg>
  );
};

export default Logo;
